import React, { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Logo from "../assets/images/logo.png";
import { CartContext } from "./cartContext"; 
import CartModal from "./cartModal"; 
import Toast from "./toast";
import { useToast } from "./newToast";

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isCartOpen, setCartOpen] = useState(false); 
  const { toastMessage, showToast } = useToast();
  
  const { cartItems } = useContext(CartContext); 
  const totalItemsInCart = cartItems.reduce((total, item) => total + item.quantity, 0);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`bg-black text-white transition-all duration-300 py-2 px-2 md:px-24 flex justify-between items-center ${
        isSticky ? "border-b border-b-orange-500 sticky top-0 z-50" : ""
      }`}
    >
      <div className="flex items-center">
        <a href="/" className='flex'>
          <img src={Logo} alt="Logo" className="h-14 w-12" />
          <span className="ml-2 text-xl font-semibold mt-3">CODESIGN</span>
        </a>
      </div>

      <div className="md:hidden flex items-center space-x-0">
        <div
          onClick={toggleMobileMenu}
          className="text-white hover:text-orange-500 focus:outline-none"
        >
          <FontAwesomeIcon icon={faBars} className="h-6 w-6 fill-current" />
        </div>
      </div>
      <nav className={`hidden md:flex items-center space-x-6`}>
        <a href="/" className="hover:text-orange-500">Home</a>
        <a href="/services" className="hover:text-orange-500">Services</a>
        {/* <a href="/package" className="hover:text-orange-500">Packages</a> */}
        <a href="/blog" className="hover:text-orange-500">Blog</a>
        <a href="/about" className="hover:text-orange-500">About Us</a>
        <a href="/contact" className="hover:text-orange-500">Contact Us</a>
        
        {/* <div className="relative cursor-pointer" onClick={() => setCartOpen(true)}>
          <FontAwesomeIcon icon={faShoppingCart} className="h-6 w-6" />
          <span className="absolute -top-2 -right-2 bg-orange-500 text-white text-xs rounded-full px-2">
            {totalItemsInCart} 
          </span>
        </div> */}
      </nav>
      {isMobileMenuOpen && (
        <div className="md:hidden mt-2 flex flex-col space-y-2">
          <nav className="flex flex-col">
            <a href="/" className="hover:text-orange-500">Home</a>
            <a href="/services" className="hover:text-orange-500">Services</a>
            {/* <a href="/package" className="hover:text-orange-500">Packages</a> */}
            <a href="/blog" className="hover:text-orange-500">Blog</a>
            <a href="/about" className="hover:text-orange-500">About Us</a>
            <a href="/contact" className="hover:text-orange-500">Contact Us</a>
          </nav>
        </div>
      )}
      {/* <div className={`md:hidden relative cursor-pointer -ml-8`} onClick={() => setCartOpen(true)}>
        <FontAwesomeIcon icon={faShoppingCart} className="h-6 w-6" />
        <span className="absolute -top-2 -right-2 bg-orange-500 text-white text-xs rounded-full px-2">
          {totalItemsInCart} 
        </span>
      </div> */}

      {isCartOpen && <CartModal closeModal={() => setCartOpen(false)} />}
      {showToast && <Toast message={toastMessage} onClose={() => showToast(false)} />}
    </header>
  );
};

export default Header;



// import React, { useContext, useState , useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
// import Logo from "../assets/images/logo.png";
// import { CartContext } from "./cartContext"; 
// import CartModal from "./cartModal"; 
// import Toast from "./toast";
// import { useToast } from "./newToast";


// const Header = () => {
//   const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
//   const [isSticky, setIsSticky] = useState(false);
//   const [isCartOpen, setCartOpen] = useState(false); 
//   const { toastMessage, showToast } = useToast();
  
//   const { cartItems } = useContext(CartContext); 
//   const totalItemsInCart = cartItems.reduce((total, item) => total + item.quantity, 0)

//   const toggleMobileMenu = () => {
//     setMobileMenuOpen(!isMobileMenuOpen);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 0) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header
//       // className={`bg-gray-800 text-white transition-all duration-300 py-2 px-2 md:px-24 flex flex-wrap md:flex-nowrap justify-between items-center ${
//       //   isSticky ? "bg-gray-800 text-white sticky top-0 z-50" : ""
//       // }`}
//       className={`bg-black text-white transition-all duration-300 py-2 px-2 md:px-24 flex flex-wrap md:flex-nowrap justify-between items-center ${
//         isSticky ? "border-b border-b-orange-500 text-white sticky top-0 z-50" : ""
//       }`}
//     >
//       <div className="flex items-center">
//          <a href="/" className='flex '>
//           <img src={Logo} alt="Logo" className="h-14 w-12" />
//           <span className="ml-2 text-xl font-semibold mt-3">CODESIGN</span>
//          </a>
//       </div>
//       <div className="md:hidden ml-4">
//         <div
//           onClick={toggleMobileMenu}
//           className="text-white hover:text-orange-500 focus:outline-none"
//         >
//           <FontAwesomeIcon icon={faBars} className="h-6 w-6 fill-current" />
//         </div>
//       </div>
//       {isMobileMenuOpen && (
//         <div>
//         <div className="md:hidden mt-4">
//           <nav className="flex flex-col space-y-2">
//             <a href="/" className="hover:text-orange-500">
//               Home
//             </a>
//             <a href="/services" className="hover:text-orange-500">
//               Services
//             </a>
//             <a href="/package" className="hover:text-orange-500">
//               Packages
//             </a>
//             <a href="/blog" className="hover:text-orange-500">
//               Blog
//             </a>
//             <a href="/about" className="hover:text-orange-500">
//               About Us
//             </a>
//             <a href="/contact" className="hover:text-orange-500">
//               Contact Us
//             </a>
//           </nav>
//         </div>
//       </div>
//       )}

//       <nav className={`md:flex md:space-x-6 hidden ${isMobileMenuOpen ? "" : "md:block"}`}>
//         <a href="/" className="hover:text-orange-500">Home</a>
//         <a href="/services" className="hover:text-orange-500">Services</a>
//         <a href="/package" className="hover:text-orange-500">Packages</a>
//         <a href="/blog" className="hover:text-orange-500">Blog</a>
//         <a href="/about" className="hover:text-orange-500">About Us</a>
//         <a href="/contact" className="hover:text-orange-500">Contact Us</a>
//         <div className="relative cursor-pointer" onClick={() => setCartOpen(true)}>
//           <FontAwesomeIcon icon={faShoppingCart} className="h-6 w-6" />
//           <span className="absolute -top-2 -right-2 bg-orange-500 text-white text-xs rounded-full px-2">
//             {totalItemsInCart} 
//           </span>
//         </div>
//       </nav>
      
//         <div className="relative cursor-pointer" onClick={() => setCartOpen(true)}>
//           <FontAwesomeIcon icon={faShoppingCart} className="h-6 w-6" />
//           <span className="absolute -top-2 -right-2 bg-orange-500 text-white text-xs rounded-full px-2">
//             {totalItemsInCart} 
//           </span>
//         </div>

//       {isCartOpen && <CartModal closeModal={() => setCartOpen(false)} />}
//       {showToast && <Toast message={toastMessage} onClose={() => showToast(false)} />}
//     </header>
//   );
// };

// export default Header;


// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
// import Logo from "../assets/images/logo.png";

// const Header = () => {
//   const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
//   const [isSticky, setIsSticky] = useState(false);
//   const toggleMobileMenu = () => {
//     setMobileMenuOpen(!isMobileMenuOpen);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 0) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header
//       className={`bg-gray-800 text-white transition-all duration-300 py-2 px-2 md:px-24 flex flex-wrap md:flex-nowrap justify-between items-center ${
//         isSticky ? "bg-gray-800 text-white sticky top-0 z-50" : ""
//       }`}
//     >
//       <div className="flex items-center">
//         <img src={Logo} alt="Logo" className="h-14 w-12" />
//         <span className="ml-2 text-xl font-semibold">CODESIGN</span>
//       </div>

//       <div className="md:hidden ml-4">
//         <div
//           onClick={toggleMobileMenu}
//           className="text-white hover:text-orange-500 focus:outline-none"
//         >
//           <FontAwesomeIcon icon={faBars} className="h-6 w-6 fill-current" />
//         </div>
//       </div>
//       {isMobileMenuOpen && (
//         <div className="md:hidden mt-4">
//           <nav className="flex flex-col space-y-2">
//             <a href="/" className="hover:text-orange-500">
//               Home
//             </a>
//             <div className="relative group">
//               <a href="/services" className="hover:text-orange-500">
//                 Services
//               </a>
//             </div>
//             <a href="/package" className="hover:text-orange-500">
//               Package
//             </a>
//             <a href="/about" className="hover:text-orange-500">
//               About Us
//             </a>
//             <a href="/contact" className="hover:text-orange-500">
//               Contact Us
//             </a>
//           </nav>
//         </div>
//       )}
//       <nav
//         className={`md:flex md:space-x-6 hidden ${
//           isMobileMenuOpen ? "" : "md:block"
//         }`}
//       >
//         <a href="/" className="hover:text-orange-500">
//           Home
//         </a>
//         <div className="relative group">
//           <a href="/services" className="hover:text-orange-500">
//             Services
//           </a>
//         </div>
//         <a href="/package" className="hover:text-orange-500">
//             Package
//         </a>
//         <a href="/about" className="hover:text-orange-500">
//           About Us
//         </a>
//         <a href="/contact" className="hover:text-orange-500">
//           Contact Us
//         </a>
//       </nav>
//     </header>
//   );
// };

// export default Header;

// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
// import Logo from "../assets/images/logo.png";

// const Header = () => {
//   const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
//   const [isSticky, setIsSticky] = useState(false);

//   const toggleMobileMenu = () => {
//     setMobileMenuOpen(!isMobileMenuOpen);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 0) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header
//       className={`bg-gray-800 text-white transition-all duration-300 py-2 px-2 md:px-24 flex flex-wrap md:flex-nowrap justify-between items-center ${
//         isSticky ? "bg-gray-800 text-white sticky top-0 z-50" : ""
//       }`}
//     >
//       <div className="flex items-center">
//         <img src={Logo} alt="Logo" className="h-14 w-12" />
//         <span className="ml-2 text-xl font-semibold">CODESIGN</span>
//       </div>

//       <div className="md:hidden ml-4">
//         <div
//           onClick={toggleMobileMenu}
//           className="text-white hover:text-orange-500 focus:outline-none"
//         >
//           <FontAwesomeIcon icon={faBars} className="h-6 w-6 fill-current" />
//         </div>
//       </div>

//       {isMobileMenuOpen && (
//         <div className="md:hidden mt-4">
//           <nav className="flex flex-col space-y-2">
//             <a href="/" className="hover:text-orange-500">
//               Home
//             </a>
//             <div className="relative group">
//               <a href="/services" className="hover:text-orange-500">
//                 Services
//               </a>
//             </div>
//             {/* <a href="/pricing" className="hover:text-orange-500">
//               Pricing
//             </a> */}
//             <a href="/about" className="hover:text-orange-500">
//               About Us
//             </a>
//             <a href="/contact" className="hover:text-orange-500">
//               Contact Us
//             </a>
//           </nav>
//         </div>
//       )}

//       <nav
//         className={`md:flex md:space-x-6 absolute top-16 md:top-0 right-0 md:relative ${
//           isMobileMenuOpen ? "" : "md:block"
//         }`}
//       >
//         <a href="/" className="hover:text-orange-500">
//           Home
//         </a>
//         <div className="relative group">
//           <a href="/services" className="hover:text-orange-500">
//             Services
//           </a>
//         </div>
//         {/* <a href="/pricing" className="hover:text-orange-500">
//           Pricing
//         </a> */}
//         <a href="/about" className="hover:text-orange-500">
//           About Us
//         </a>
//         <a href="/contact" className="hover:text-orange-500">
//           Contact Us
//         </a>
//       </nav>
//     </header>
//   );
// };

// export default Header;
